import React from "react"

import PageCaseDetail from "../PageCaseDetail"
import Layout from "components/Layout/Layout"

import useChangeLanguage from "hooks/useChangeLanguage"

const CaseDetail = ({ pageContext: { slug, caseDetail, casesList, lang } }) => {
  useChangeLanguage(lang)
  return (
    <Layout>
      <PageCaseDetail
        caseDetail={caseDetail}
        casesList={casesList}
        slug={slug}
      />
    </Layout>
  )
}

export default CaseDetail
